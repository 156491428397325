
































import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import StoriesForm from "./components/StoriesForm.vue"
import StoriesTable from "./components/StoriesTable.vue"
import { StoryV2 } from "@/store/types";

export default Vue.extend({
  name: "StoriesV2",
  
  components: {
    FullScreenDialog,
    StoriesForm,
    StoriesTable
  },

  data: () => ({
    fullScreenDialog: false,
    story: null as unknown | StoryV2,
  }),

  computed: {
    ...mapGetters({
      stories: "storiesV2Module/getStories",
      total: "storiesV2Module/getTotal",
      loading: "storiesV2Module/getLoading"
    })
  },

  created() {
    this.$store.dispatch("storiesV2Module/fetch");
  },

  methods: {
    editCol(story: StoryV2): void {
      this.story = story;
      this.openFullScreenDialog();
    },

    deleteCol(story: StoryV2): void {
     this.$store.dispatch("storiesV2Module/deactivate", { _id: story._id });
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    fetch(payload: { page: number, pageSize: number }): void {
      this.$store.dispatch("storiesV2Module/fetch", payload);
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.story = null;
    },
  }
})
