













































































































import Vue from "vue"
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import {getImageDimensions, getVideoDimensions } from "@/utils";
import { actionType, ImageUpload, mediaType, StoryV2 } from "@/store/types";

export default Vue.extend({
  name: "StoriesForm",

  props: {
    story: { type: Object, default: null }
  },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      daysToExpire: [
        { text: "1d", value: 1 },
        { text: "3d", value: 3 },
        { text: "7d", value: 7 },
        { text: "15d", value: 15 },
        { text: "1m", value: 30 },
        { text: "3m", value: 90 },
        { text: "6m", value: 180 },
        { text: "1a", value: 365 },
      ],
      actionTypes: [
        { text: "Link", value: "LINK" },
        { text: "Email", value: "EMAIL" },
        { text: "Pix", value: "PIX" },
        { text: "Unset", value: "UNSET" }
      ],
      payload: {
        _id: "",
        user_id: null,
        app_ids: [],
        active: true,
        story_collection_id: "",
        media_type: mediaType.Image,
        story_duration_secs: 5,
        expiring_at: new Date().setDate(new Date().getDate() + 1),
        expiring_days: 1,
        disable_share: true,
        disable_likes: true,
        is_paid_partnership: false,
        has_video: false,
        image_versions: [],
        video_versions: [],
        action: {
          value: "",
          text: "Clique Aqui",
          type: actionType.Link
        },
        created_at: new Date(),
        updated_at: new Date(),
      } as StoryV2
    };
  },

  computed: {
    ...mapGetters({
      apps: "mobileAppsModule/getApps",
      collections: "collectionsV2Module/getCollections",
      userId: "auth/getUid"
    }),

    // FIXME, por enquanto, vamos mostrar só uma das versões
    storyImage(): string {
      const placeholder = "https://i.imgur.com/tV3k15O.jpg"
      return this.payload.image_versions[0]?.url ?? placeholder
    },

    // FIXME, por enquanto, vamos mostrar só uma das versões
    storyVideo(): string {
      const placeholder = require("../../../assets/video-placeholder.mp4")
      return this.payload.video_versions[0]?.url ?? placeholder
    }
  },

  mounted() {
    this.$store.dispatch("mobileAppsModule/fetch");
    this.$store.dispatch("collectionsV2Module/fetch");

    if (this.story) {
      Object.assign(this.payload, this.story)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      const today = new Date()
      this.payload.updated_at = new Date()
      this.payload.expiring_at = today.setDate(today.getDate() + this.payload.expiring_days)
      this.payload.user_id = this.userId

      if (this.story) {
        this.$store.dispatch("storiesV2Module/update", this.payload);
      } else {
        this.$store.dispatch("storiesV2Module/create", this.payload);
      }

      this.$emit("close");
    },

    validateForm(): Boolean {
      // if (!this.payload.sku) {
      //   this.showError("Insira o SKU da oferta!");
      //   return false;
      // }
      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },

    getFile(file: File) {
      const isImage = file.type.includes("image");
      const isVideo = file.type.includes("video");

      if (!isImage && !isVideo) {
        return this.$store.dispatch("app/showCustomError", "Arquivo não Suportado!");
      }

      const folder = isImage ? "/stories/images" : "/stories/videos";

      const extension = file.name.split(".")[1];

      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const image: ImageUpload = {
          folder,
          filename: `${uuidv4()}.${extension}`,
          file: new Uint8Array(target?.result as ArrayBuffer),
          metadata: {
            contentType: file.type,
            size: file.size,
          }
        }

        const dimensions = isImage
            ? await getImageDimensions(file)
            : await getVideoDimensions(file)

        this.$store.dispatch("uploader/upload", image)
            .then(url => {
              const storedFile = {
                url,
                width: dimensions.width,
                height: dimensions.height
              }

              if(isImage) {
                this.payload.image_versions = [storedFile]
              } else {
                this.payload.video_versions = [storedFile]
                this.payload.media_type = mediaType.Video;
              }
            })
      };
      reader.readAsArrayBuffer(file);
    },
  }
})
