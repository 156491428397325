var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stories-table"},[_c('ConfirmDialog',{attrs:{"title":"Arquivar o Story?","text":"Essa ação não pode ser revertida!","show":_vm.showArchiveDialog},on:{"close":function($event){_vm.showArchiveDialog = false},"confirm":_vm.onDelete}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.options,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pageSize,"loading":_vm.loading,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.media_type === _vm.mediaType.Image)?_c('v-img',{staticStyle:{"width":"50px","margin":"5px","object-fit":"cover"},attrs:{"src":_vm.extractImage(item.image_versions)}}):_c('video',{staticStyle:{"width":"50px","height":"88px","margin":"5px","object-fit":"cover"},attrs:{"src":_vm.extractVideo(item.video_versions)}})]}},{key:"item.expiring_at",fn:function(ref){
var item = ref.item;
return [_c('small',[_c('b',[_vm._v(_vm._s(new Date(item.expiring_at).toLocaleString()))])]),_c('small',{staticClass:"d-block"},[_vm._v(_vm._s(item.media_type))])]}},{key:"item.expired",fn:function(ref){
var item = ref.item;
return [_c('small',[_c('b',[_vm._v(_vm._s(new Date(item.expiring_at) < new Date() ? 'SIM' : 'NÃO'))])])]}},{key:"item.story_collection_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCollectionName(item.story_collection_id))+" ")]}},{key:"item.apps",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm.buildAppNames(item.app_ids)))])]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.disable_share ? 'red' : 'green'}},on),[_c('v-icon',[_vm._v("mdi-share")])],1)]}}],null,true)},[_c('span',[_vm._v("Compartilhamento "+_vm._s(item.disable_share ? 'Desativado' : 'Ativado'))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.disable_likes ? 'red' : 'green'}},on),[_c('v-icon',[_vm._v("mdi-thumb-up")])],1)]}}],null,true)},[_c('span',[_vm._v("Curtidas "+_vm._s(item.disable_likes ? 'Desativadas' : 'Ativadas'))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.is_paid_partnership ? 'green' : 'red'}},on),[_c('v-icon',[_vm._v("mdi-cash-multiple")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.is_paid_partnership ? 'É uma' : 'Não é uma')+" Parceria Paga")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red","disabled":!item.active},on:{"click":function($event){return _vm.showDialog(item)}}},[_c('v-icon',[_vm._v("mdi-archive")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }