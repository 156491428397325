


























































































import Vue from "vue"
import { mapGetters } from "vuex";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {
  ImageVersion,
  mediaType,
  MobileApp,
  StoryV2,
  VideoVersion
} from "@/store/types";

export default Vue.extend({
  name: "StoriesTable",

  components: {
    ConfirmDialog,
  },

  props: {
    items: { type: Array, default: null },
    loading: { type: Boolean, default: false },
    total: { type: Number, default: 0 }
  },

  data: () => ({
    mediaType,
    showArchiveDialog: false,
    storyToArchive: null as unknown | StoryV2,
    options: {},
    page: 1,
    pageSize: 10,
    headers: [
      {
        text: "Imagem",
        value: "image",
        sortable: false,
        width: "100"
      },
      {
        text: "Expiração",
        value: "expiring_at",
        sortable: false,
        width: "150"
      },
      {
        text: "Expirado",
        value: "expired",
        sortable: false,
        width: "150"
      },
      {
        text: "Coleção",
        value: "story_collection_id",
        sortable: false,
        width: "150"
      },
      {
        text: "Views",
        value: "views_count",
        align: "center",
        width: "100",
        sortable: false
      },
      {
        text: "Apps",
        value: "apps",
        align: "center",
        sortable: false
      },
      {
        text: "Informações",
        value: "info",
        align: "center",
        width: "150",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  computed: {
    ...mapGetters({
      collections: "collectionsV2Module/getCollections",
      apps: "mobileAppsModule/getApps",
    }),
  },

  watch: {
    options: {
      handler (options) {
        this.fetch(options.page, options.itemsPerPage);
      },
      deep: true,
    },
  },

  mounted() {
    this.$store.dispatch("collectionsV2Module/fetch");
  },

  methods: {
    showDialog(item: StoryV2): void {
      this.showArchiveDialog = true
      this.storyToArchive = item;
    },

    onEdit(item: StoryV2): void {
      this.$emit("onEdit", item)
    },

    onDelete(): void {
      this.$emit("onDelete", this.storyToArchive)
      this.showArchiveDialog = false
    },

    fetch(page: number, pageSize: number): void {
      if (page === this.page && pageSize === this.pageSize) return;
      this.page = page;
      this.pageSize = pageSize;

      // FIXME o backend começa a paginação em 0, resolver isso depois
      this.$emit("fetch", { page: page - 1, pageSize })
    },

    getCollectionName(collId: string): string {
      if (this.collections.length) {
        const [collection] = this.collections.filter((coll: StoryV2) => coll._id === collId)
        return collection?.name ?? ""
      }
      return ""
    },

    extractImage(images: Array<ImageVersion>): string {
      return images[0]?.url ?? ""
    },

    extractVideo(videos: Array<VideoVersion>): string {
      return videos[0]?.url ?? ""
    },

    buildAppNames(ids: string[]): string {
      return this.apps
          .filter((app: MobileApp) => ids.includes(app._id))
          .map((app: MobileApp) => app.app_name)
          .join(", ")
    }
  }
})
